import { useCallback } from "react";
import Api from "../../../utils/api.utils";
import { useSelector } from "react-redux";
import { size } from "lodash";

export function useApi() {
  const tokenId =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiOUJDOEQwRjEtMTdCMi00Rjk1LUI5ODItRUEyNTczRUM3NUJDIiwiZGJfbmFtZSI6IkNSTV8wMDAwMyIsImlhdCI6MTcxODAyNjEwMCwiZXhwIjoxNzQ5NTYyMTAwfQ.i6VioNpJmjMsOORGEdk0SNHhHzWKye9Vxk12QgE09YE";

  const apiPost = useCallback(
    async (config) => {
      let mResponse = [];
      try {
        if (size(tokenId) === 0) {
          mResponse = await Api.post({
            ...config,
            header: "",
          });
        } else {
          mResponse = await Api.post({
            ...config,
            header: `Bearer ` + tokenId,
          });
        }
      } catch (err) {
        console.log("Post Error :- " + err);
      } finally {
        return mResponse;
      }
    },
    [tokenId]
  );

  return {
    apiPost,
  };
}
