import React, { useEffect } from "react";
import HomeBanner from "../homeContainers/HomeBanner";
import BBBang from "../homeContainers/BBBang";
import InquiryAndGallary from "../homeContainers/InquiryAndGallary";
import MenuSection from "../homeContainers/MenuSection";
import Franchise from "../homeContainers/Franchise";
import { useLocation } from "react-router-dom";

const Home = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <HomeBanner />
      <BBBang />
      <MenuSection />
      <Franchise />
      <InquiryAndGallary />
    </>
  );
};

export default Home;
