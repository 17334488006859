import { combineReducers, createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import bigBurgerReducer from "./data/reducers/bigBurger.reducer";
import restaurantCategoryReducer from "./data/reducers/bigBurger.reducer";

const rootReducer = combineReducers({
  bigBurger: bigBurgerReducer,
  restaurantCategory: restaurantCategoryReducer,
});

const persistConfig = {
  key: "crm-admin",

  storage,
  whitelist: ["appState"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeWithDevTools());

export const persistor = persistStore(store);

export default store;
