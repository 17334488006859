import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import GalleryBG from "../assets/images/gallery_bg.png";
import Gallery_left from "../assets/images/Gallery_left.png";
import Gallery_right from "../assets/images/Gallery_right.png";

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="flex w-full justify-between gap-8 px-4 md:px-8 lg:px-20 xl:px-36 absolute top-10">
      <button onClick={() => previous()} className="z-50 ">
        <img
          src={Gallery_left}
          alt="Left Arrow"
          className="w-10 xl:w-12 object-contain"
        />
      </button>
      <h3 className="font-title text-5xl lg:text-7xl text-white">Gallery</h3>
      <button onClick={() => next()} className="z-50">
        <img
          src={Gallery_right}
          alt="Left Arrow"
          className="w-10 xl:w-12 object-contain"
        />
      </button>
    </div>
  );
};

const GalleryCarousel = (props) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div
      className="relative flex flex-col bg-no-repeat bg-center bg-cover lg:pb-8 pt-24 lg:pt-32"
      style={{ backgroundImage: `url(${GalleryBG})` }}
    >
      <Carousel
        className="lg:h-96 p-8 relative"
        additionalTransfrom={0}
        arrows={false}
        autoPlaySpeed={3000}
        centerMode={true}
        containerClass=""
        customButtonGroup={<ButtonGroup />}
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={true}
        renderDotsOutside={false}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass="gap-4"
        swipeable
        slidesToSlide={1}
        responsive={responsive}
      >
        {props.images.map((item, id) => {
          return (
            <img
              key={id}
              src={item.image}
              alt="Gallery Image"
              className="object-contain"
            />
          );
        })}
      </Carousel>
    </div>
  );
};

export default GalleryCarousel;
