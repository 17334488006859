import React from "react";
import BannerBG from "../assets/images/about/about_page_banner.png";
import Star from "../assets/images/LittleStar.png";
import BannerBG1 from "../assets/images/about/about_page_banner_1.png";
import BurgerLeft from "../assets/images/about/BurgerLeft.png";
import BurgerRight from "../assets/images/about/BurgerRight.png";
import BottomBurger from "../assets/images/about/BottomBurger.png";
import OnFIreMobile from "../assets/images/about/OnFIreMobile.png";
import ChowPowderMobile from "../assets/images/about/ChowPowderMobile.png";

const AboutBanner = () => {
  return (
    <section
      className={`relative flex justify-center items-center w-full h-full z-10 overflow-hidden`}
    >
      <img
        src={BannerBG}
        alt="Banner Image"
        className="object-cover w-full min-h-full hidden lg:flex"
      />
      <img
        src={BannerBG1}
        alt="Banner Image"
        className="object-cover w-full min-h-screen flex lg:hidden"
      />
      <div className="absolute flex lg:hidden bottom-36 -left-52 w-full">
        <img
          src={BurgerLeft}
          alt="Left Burger"
          className="object-contain h-73"
        />
        <img
          src={OnFIreMobile}
          alt="On Fire"
          className="object-contain h-20 -ml-20 mt-20 z-10"
        />
      </div>
      <img
        src={BurgerRight}
        alt="Right Burger"
        className="object-contain absolute -right-56 md:-right-44 h-80 md:h-96 top-56 md:top-48 flex lg:hidden"
      />
      <div className="absolute -right-20 -bottom-2 flex lg:hidden w-full justify-end">
        <img
          src={ChowPowderMobile}
          alt="Chow Powder"
          className="object-contain h-20 -mr-44"
        />
        <img
          src={BottomBurger}
          alt="Right Burger"
          className="object-contain h-80"
        />
      </div>
      <img
        src={Star}
        alt="Star"
        className="object-contain absolute w-4 lg:w-6 top-48 lg:top-36 xl:top-60 left-12 lg:left-40 xl:left-64"
      />
      <img
        src={Star}
        alt="Star"
        className="object-contain absolute w-6 lg:w-8 top-20 lg:top-24 xl:top-36 right-1/3"
      />
      <img
        src={Star}
        alt="Star"
        className="object-contain absolute w-8 lg:w-10 top-64 lg:top-40 xl:top-60 right-4 md:right-12 lg:right-32 xl:right-56 z-10"
      />
      <div className="flex absolute flex-col lg:justify-center items-center flex-1 w-full h-full pb-32 xl:pb-44 px-4 top-0 left-0 mt-24 lg:mt-0">
        <span className="font-title text-yellow text-6xl md:text-8xl xl:text-8xl text-left w-full max-w-xs md:max-w-xl xl:max-w-lg">
          Market
        </span>
        <span className="font-title text-yellow text-6xl md:text-8xl xl:text-8xl text-right w-full max-w-xs md:max-w-sm xla:max-w-lg pb-4 md:pb-12 lg:pb-0">
          disruption
        </span>
        <p className="text-white font-medium text-xl lg:text-sm xl:text-2xl text-center max-w-xs md:max-w-sm lg:max-w-sm xl:max-w-md px-8 md:0">
          Despite being in its infancy stage, BBC has walked the walk,
          disrupting the market equilibrium with its innovative business model
        </p>
      </div>
    </section>
  );
};

export default AboutBanner;
