import React from "react";
import Carousel from "react-multi-carousel";

const CustomDots = ({ onClick, ...rest }) => {
  const {
    onMove,
    index,
    active,
    carouselState: { currentSlide, deviceType },
  } = rest;
  return (
    <button
      className={
        active
          ? "bg-yellow rounded-full w-12 h-2 mx-2 opacity-100"
          : "bg-yellow opacity-20 rounded-full w-12 h-2 mx-2"
      }
      onClick={() => onClick()}
    ></button>
  );
};

const MarketNeedsCarousel = (props) => {
  const { data } = props;
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div className="w-3/4 lg:w-full flex relative self-start">
      <Carousel
        className="flex w-full"
        additionalTransfrom={0}
        arrows={false}
        containerClass=""
        keyBoardControl
        minimumTouchDrag={80}
        shouldResetAutoplay
        showDots={true}
        slidesToSlide={1}
        swipeable
        responsive={responsive}
        customDot={<CustomDots />}
      >
        {data.map((item, id) => {
          return (
            <div key={id} className="flex flex-col pb-12 xl:gap-4 w-full">
              <h3 className="font-description-bolditalic text-yellow text-2xl md:text-3xl xl:text-5xl">
                {item.title}
              </h3>
              <p className="font-medium text-black text:base xl:text-xl">
                {item.desc}
              </p>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default MarketNeedsCarousel;
