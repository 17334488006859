import React, { useCallback, useEffect } from "react";
import FormElement1 from "../assets/images/franchise/catchup.png";
import FormElement2 from "../assets/images/franchise/cupcake.png";
import BannerBG from "../assets/images/about/vission_mission_bg.png";
import BlackDivider from "../assets/images/franchise/black_divider.png";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import useBigBurger from "../../src/data/talons/BigBurger/useBigBurger";
import { resetInquiryFormData } from "../../src/data/reducers/bigBurger.reducer";

const InquiryForm = () => {
  const { inquiryEntrySchema, InquiryEntryFranchise, alert, setAlert } =
    useBigBurger();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setValue,
  } = useForm({
    resolver: joiResolver(inquiryEntrySchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      contact_num: "",
      email_id: "",
    },
  });

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 10));
  };

  const onUserSubmit = useCallback(async () => {
    try {
      const res = await InquiryEntryFranchise({
        ...getValues(),
      });
      if (res == true) {
        setAlert(true);
        reset();
        dispatch(resetInquiryFormData());
        setValue("");
      }
    } catch (err) {
      console.log("errr", err);
    }
  }, [InquiryEntryFranchise]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert(false);
    }, 3000);

    // To clear or cancel a timer, you call the clearTimeout(); method,
    // passing in the timer object that you created into clearTimeout().

    return () => clearTimeout(timer);
  }, [alert]);

  const dispatch = useDispatch();
  return (
    <div className="flex flex-col w-full relative justify-center items-center lg:px-20 xl:px-36 pb-40 -mt-20 pt-16 lg:-mt-80 overflow-x-hidden">
      <img
        src={FormElement2}
        alt="Element"
        className="absolute bottom-28 md:bottom-40 xl:bottom-1/4 left-2 md:left-12 lg:left-28 xl:left-60 object-contain w-16 md:w-24 xl:w-32 z-10"
      />
      <img
        src={FormElement1}
        alt="Element"
        className="object-contain w-24 md:w-24 xl:w-32 absolute top-8 lg:top-12 -right-4 md:-right-2 lg:right-40 xl:right-72 z-10"
      />
      <p className="bg-black text-white w-4/5 lg:w-3/5 py-4 px-6 rounded-t-3xl text-base md:text-2xl xl:text-4xl text-center font-description-bold">
        INQUIRY FORM
      </p>
      <form
        className="relative w-full lg:rounded-3xl flex flex-col gap-4 md:gap-10 justify-center items-center p-8 md:p-12 xl:p-20 pb-12 bg-no-repeat bg-center bg-cover"
        style={{
          backgroundImage: `url(${BannerBG})`,
        }}
      >
        <div className="flex w-full gap-4 md:gap-10 flex-wrap flex-col lg:flex-row">
          <input
            type="text"
            placeholder="First Name"
            className="flex-1 py-2 md:py-4 px-4 md:px-6 rounded-xl border-black border-opacity-50 border-2 bg-transparent text-white placeholder-gray-50"
            {...register("first_name")}
            autoComplete="false"
          />
          <input
            type="text"
            placeholder="Last Name"
            className="flex-1 py-2 md:py-4 px-4 md:px-6 rounded-xl border-black border-opacity-50 border-2 bg-transparent text-white placeholder-gray-50"
            {...register("last_name")}
            autoComplete="false"
          />
        </div>
        <div className="flex w-full gap-4 md:gap-10 flex-col lg:flex-row">
          <input
            type="text"
            placeholder="Your E-mail ID"
            className="flex-1 py-2 md:py-4 px-4 md:px-6 rounded-xl border-black border-opacity-50 border-2 bg-transparent text-white placeholder-gray-50"
            {...register("email_id")}
            autoComplete="false"
          />
          <input
            type="tel"
            placeholder="Mobile Number"
            className="flex-1 py-2 md:py-4 px-4 md:px-6 rounded-xl border-black border-opacity-50 border-2 bg-transparent text-white placeholder-gray-50"
            {...register("contact_num")}
            autoComplete="false"
            onInput={(e) => onlyNumbers(e)}
          />
        </div>
        <button
          className="px-20 py-2 text-yellow bg-black rounded-xl font-extrabold text-xl lg:text-3xl"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(onUserSubmit());
          }}
        >
          SUBMIT
        </button>
        {alert === true ? (
          <div className="fade_in" style={{ color: "white" }}>
            Thank You For Showing Interest!
          </div>
        ) : null}
        {/* <div className="w-full flex flex-col mx-20 justify-center items-center overflow-hidden">
          <img
            src={BlackDivider}
            alt="Black Divider"
            className="object-cover md:object-contain absolute bottom-12 md:bottom-12 xl:bottom-16 w-1/3 md:w-auto h-20 md:h-16 xl:h-24"
          />
          <p className="text-black py-4 text-base md:text-2xl xl:text-4xl text-center w-11/12 md:w-auto hidden md:flex">
            FOR FRANCHISE INQUIRY, CALL{" "}
            <span className="font-description-bold"> +91 99048 84433</span>
          </p>
          <p className="text-black py-4 text-base md:text-2xl xl:text-4xl text-center w-11/12 md:w-auto flex flex-col md:hidden items-center justify-center">
            FOR MORE INFORMATION,
            <span className="flex text-center">
              CALL{" "}
              <span className="font-description-bold"> +91 99048 84433</span>
            </span>
          </p>
        </div> */}
      </form>
    </div>
  );
};

export default InquiryForm;
