import React, { useDeferredValue, useEffect } from "react";
import MenuCarouasel from "../components/MenuCarouasel";
import Swiggy from "../assets/images/Swiggy.png";
import Zomato from "../assets/images/Zomato.png";
import menuURL from "../assets/pdfs/BBC_MENU_MOTERA_PRINT_FILE.pdf";
import {
  getRestaurantProductList,
  getBranchMasterComboList,
  getBranchData,
} from "../data/selectors/bigBurger.selector";
import { useDispatch, useSelector } from "react-redux";
import useBigBurger from "../data/talons/BigBurger/useBigBurger";
import { find } from "lodash";
export default function MenuSection(props) {
  const restaurantProduct = useSelector(getRestaurantProductList);
  const branchMasterCombo = useSelector(getBranchMasterComboList);
  const dispatch = useDispatch();
  const {
    handleBranchMasterComboChange,
    handleRestaurantCategoryNameChange,
    formLoadEvent,
    fetchRestaurantProductList,
    branchId,
    fetchBranchMasterComboList,
  } = useBigBurger();

  const branchData = find(branchMasterCombo, ["id", branchId]);

  useEffect(() => {
    fetchBranchMasterComboList();
    fetchRestaurantProductList();
  }, [branchId]);

  // const MenuSection = () => {
  //   const menuData = [
  //     {
  //       title: "CLASSIC BIG BURGER",
  //       category: [
  //         {
  //           title: "CLASSIC BIG BURGER",
  //           desc: `(The world's favourite Indian burger. Crispy vegetable patty with delicious mayo, fresh tomatoes & crunchy onions)`,
  //           regular: 49,
  //           multigrain: 59,
  //           bb: 99,
  //         },
  //         {
  //           title: "HOT SCHEZWAN BURGER",
  //           desc: `(A fusion of delicious flavors made with your favourite potato herb chilli patty, layered with onions, delicious tandoori sauce, hot chilli garlic sauce, fresh lettuce & onions)`,
  //           regular: 79,
  //           multigrain: 89,
  //           bb: 149,
  //         },
  //         {
  //           title: "INDIAN MASALA BURGER",
  //           desc: `(Our signature burger featuring 7 delicious layers. Fried masala patty with our signature sauces & onions, fresh tomatoes & lettuce)`,
  //           regular: 99,
  //           multigrain: 109,
  //           bb: 179,
  //         },
  //       ],
  //     },
  //     {
  //       title: "BB FUSION",
  //       category: [
  //         {
  //           title: "CHEESE BURST BURGER",
  //           desc: `(Rich and filling cheese patty coated in spicy, crispy batter topped with fresh vegetables & our signature sauce)`,
  //           regular: 169,
  //           multigrain: 189,
  //           bb: 249,
  //         },
  //         {
  //           title: "AMERICAN CHEESE BURST BURGER",
  //           desc: `(A fusion of delicious flavors made with your favourite potato herb chilli patty, layered with onions, delicious tandoori sauce, hot chilli garlic sauce & fresh lettuce)`,
  //           regular: 189,
  //           multigrain: 199,
  //           bb: 279,
  //         },
  //         {
  //           title: "BB DOUBLE CHEESE BURST BURGER",
  //           desc: `(Perfectly fusioned for cheese lovers. Cheese stuffed patty, fresh vegetables & delicious sauces from our big burger)`,
  //           regular: 199,
  //           multigrain: 219,
  //           bb: 299,
  //         },
  //       ],
  //     },
  //   ];
  //   const branches = [
  //     {
  //       id: 1,
  //       branch: "Motera",
  //     },
  //     {
  //       id: 2,
  //       branch: "PDPU",
  //     },
  //     {
  //       id: 3,
  //       branch: "Kudasan",
  //     },
  //     {
  //       id: 4,
  //       branch: "Shilaj",
  //     },
  //     {
  //       id: 5,
  //       branch: "Panvel",
  //     },
  //     {
  //       id: 6,
  //       branch: "Odisha",
  //     },
  //     {
  //       id: 7,
  //       branch: "Navrangpura",
  //     },
  //   ];
  return (
    <section className="min-h-screen pb-24 xl:pb-16 pt-16 px-4 md:px-8 lg:px-20 xl:px-36 flex flex-col justify-center items-center w-full bg-no-repeat bg-center bg-cover bg-menuBannerMobile md:bg-menuBanner">
      <div className="flex gap-4 md:gap-8 justify-center items-center flex-col md:flex-row">
        <p className="font-title text-purple text-7xl md:text-8xl xl:text-9xl">
          MENU
        </p>
        <p className="font-title text-white text-4xl xl:text-5xl text-center md:text-left">
          Big on Options,
          <br /> Bigger on Flavor
        </p>
      </div>
      <select
        onChange={handleBranchMasterComboChange}
        className="bg-yellow text-black font-description-semibold border-purple border-2 rounded-full py-2 px-8 text-md md:text-xl lg:text-2xl mt-6 mb-4"
      >
        {branchMasterCombo.map((item, id) => {
          return (
            <option value={item.id} key={item.id}>
              {item.label}
            </option>
          );
        })}
      </select>
      <MenuCarouasel data={restaurantProduct} />
      <div className="flex flex-wrap gap-4 md:gap-8 xl:gap-10 justify-center items-center">
        <p className="text-xl lg:text-2xl xl:text-3xl text-white font-description-bolditalic">
          ALSO AVAILABLE ON
        </p>
        <div className="flex flex-wrap gap-4 md:gap-8 xl:gap-10 justify-center items-center">
          <a href={branchData?.swiggy_link} target="_blank">
            <img
              src={Swiggy}
              alt="Order on Swiggy"
              className="object-contain w-40 xl:w-auto"
            />
          </a>
          <a href={branchData?.zomato_link} target="_blank">
            <img
              src={Zomato}
              alt="Order on Zomato"
              className="object-contain w-40 xl:w-auto"
            />
          </a>
          <button className="bg-yellow border-purple text-purple rounded-full border-2  font-description-semibold xl:font-description-bold px-4 xl:px-8 py-2 xl:py-4 text-md xl:text-2xl">
            <a
              type="media_type"
              href={menuURL}
              download="menu"
              target="_blank"
              rel="noopener"
            >
              Download Menu
            </a>
          </button>
        </div>
      </div>
    </section>
  );
}

// export default MenuSection;
