import React from "react";
import Burger from "../assets/images/home/home_banner_burger.png";
import Smiley from "../assets/images/home/home_banner_smiley.png";

const HomeBanner = () => {
  return (
    <section
      className={`relative flex w-full bg-no-repeat bg-center bg-cover md:min-h-screen h-full pt-32 pb-20 bg-homeBannerBGMobile md:bg-homeBannerBG overflow-x-hidden`}
    >
      <div className="flex flex-col gap-8 lg:flex-row items-center justify-between pl-0 md:pl-8 lg:pl-20 xl:pl-40 z-10 w-full">
        <div className="flex flex-col justify-center h-full px-4">
          <h1 className="text-6xl md:text-8xl font-title text-white tracking-wide leading-snug text-center md:text-left">
            FRESH,
            <br /> FAST, AND <br /> OH-SO-BIG
          </h1>
          <span className="flex gap-4 items-center mt-8 text-2xl md:text-4xl text-yellow font-title">
            <img
              src={Smiley}
              alt="Smiley"
              className="w-8 md:w-10 object-contain"
            />
            BEWARE! delicious details ahead
          </span>
        </div>
        <div className="flex relative -mb-20 -mr-24 md:mr-0 md:mb-0 w-11/12 md:w-full lg:w-7/12 2xl:w-1/2 pl-2">
          <img
            src={Burger}
            alt="Burger"
            className="flex-1 md:mt-14 lg:mt-12 xl:mt-32 2xl:mt-24 z-10 object-cover"
          />
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
