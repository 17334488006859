import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    inquiryList: [],
    restaurantProduct: [],
    branchMasterCombo: [],
    restaurantCategory: [],
  },
};

export const bigBurgerSlice = createSlice({
  name: "bigBurgerReducer",
  initialState,
  reducers: {
    resetInquiryFormData: (state, action) => {
      return {
        ...initialState,
      };
    },
    setInquiryList: (state, action) => {
      state.filters.inquiryList = action.payload;
    },
    setRestaurantProductList: (state, action) => {
      state.filters.restaurantProduct = action.payload;
    },
    setBranchMasterComboList: (state, action) => {
      state.filters.branchMasterCombo = action.payload;
    },
    setRestaurantCategoryList: (state, action) => {
      state.filters.restaurantCategory = action.payload;
    },
  },
});

export const {
  resetInquiryFormData,
  setInquiryList,
  setRestaurantProductList,
  setBranchMasterComboList,
  setRestaurantCategoryList,
} = bigBurgerSlice.actions;

export default bigBurgerSlice.reducer;
