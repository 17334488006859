import React from "react";
import Banner from "../assets/images/contact/banner_1.png";
import CupCake from "../assets/images/contact/sticker.png";
import Catchup from "../assets/images/contact/sticker_2.png";

const ContactBanner = () => {
  return (
    <section className="relative flex z-10">
      <img
        src={Banner}
        alt="Contact Banner"
        className="object-cover lg:object-contain w-full h-80 md:h-96 lg:h-full"
      />
      <div className="w-full h-full flex absolute top-0 left-0 justify-center items-center">
        <h1 className="font-title text-yellow text-6xl md:text-8xl mt-12 md:mt-8">
          Contact Us
        </h1>
        <img
          src={CupCake}
          alt="Cup cake"
          className="absolute object-contain w-16 md:w-24 lg:w-32 2xl:w-40 right-4 md:right-12 xl:right-64 top-24 md:top-28"
        />
        <img
          src={Catchup}
          alt="Catchup"
          className="absolute object-contain w-16 md:w-24 lg:w-32 2xl:w-40 -bottom-4 md:-bottom-8 left-12 md:left-20 lg:left-36"
        />
      </div>
    </section>
  );
};

export default ContactBanner;
