import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Gallery_left from "../assets/images/Gallery_left.png";
import Gallery_right from "../assets/images/Gallery_right.png";
import TestimonialCard from "../components/TestimonialCard";
import BannerBG from "../assets/images/franchise/review_back.png";

const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (
    <button onClick={() => onClick()} className="absolute right-0">
      <img
        src={Gallery_right}
        alt="Left Arrow"
        className="w-6 md:w-10 xl:w-12 object-contain"
      />
    </button>
  );
};
const CustomLeftArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (
    <button onClick={() => onClick()} className="absolute left-0">
      <img
        src={Gallery_left}
        alt="Right Arrow"
        className="w-6 md:w-10 xl:w-12 object-contain"
      />
    </button>
  );
};

const Testimonial = () => {
  const data = [
    {
      name: "Rajesh Shah",
      desc: "The food is excellent and pocket friendly with no compromise of taste, quality and quantity. Pizza n burger starts from 59 only...Great ..",
    },
    {
      name: "Samiksha Yadav",
      desc: "Being a burger lover, I have tried burgers from dozens of places but I must say, their signature big burger is actually unparalleled. Loved it to the core. You guys have earned a loyal customer today.",
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <section
      className="relative flex flex-col w-full h-full px-4 md:px-8 lg:px-20 xl:px-36 bg-no-repeat bg-center bg-cover"
      style={{
        backgroundImage: `url(${BannerBG})`,
      }}
    >
      <Carousel
        className="relative pt-24 lg:pt-12 py-8 lg:py-12 px-4 md:px-24 lg:px-26 xl:px-14"
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        containerClass="carousel-container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={true}
        renderDotsOutside={false}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
        responsive={responsive}
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
      >
        {data.map((review, id) => {
          return (
            <div key={id}>
              <TestimonialCard review={review} id={id} />
            </div>
          );
        })}
      </Carousel>
    </section>
  );
};

export default Testimonial;
