export const getInquiryList = (store) =>
  store.bigBurger.filters.inquiryList;

  export const getRestaurantProductList = (store) =>
  store.bigBurger.filters.restaurantProduct;

  export const getBranchMasterComboList = (store) =>
  store.bigBurger.filters.branchMasterCombo;

  export const getRestaurantCategoryList = (store) =>
  store.bigBurger.filters.restaurantCategory;