import React from "react";
import VimalPatel from "../assets/images/about/vimal_patel.png";
import BrijeshChauhan from "../assets/images/about/brijesh_chauhan.png";
import VijethJain from "../assets/images/about/vijeth_jain.png";
import BigStarHalf from "../assets/images/BigStarHalf.png";
import MiddleStarHalf from "../assets/images/MiddleStarHalf.png";
import LittleStar from "../assets/images/LittleStar.png";

const AboutFounders = () => {
  return (
    <section className="relative flex flex-col lg:flex-row gap-16 px-4 md:px-8 lg:px-20 xl:px-36 pb-16">
      <img
        src={BigStarHalf}
        alt="Big Star"
        className="absolute -left-3 top-1/2 object-contain w-8 hidden lg:flex"
      />
      <img
        src={MiddleStarHalf}
        alt="Middle Star"
        className="absolute right-0 top-8 object-contain w-10 hidden lg:flex"
      />
      <img
        src={LittleStar}
        alt="Little Star"
        className="object-contain w-8 lg:w-auto absolute left-3/4 lg:left-1/2 top-24"
      />
      <img
        src={LittleStar}
        alt="Little Star"
        className="object-contain w-8 lg:w-auto absolute left-28 bottom-96 flex lg:hidden"
      />
      <div className="w-full flex flex-col md:flex-row lg:flex-col gap-0 lg:gap-4">
        <div className="w-full md:w-2/3 lg:w-full md:-ml-8 lg:ml-0 flex relative">
          <img
            src={VimalPatel}
            alt="Vimal Patel Co-Founder"
            className="w-3/4 object-contain"
          />
          <div className="flex flex-col justify-start items-start absolute bottom-2 md:bottom-80 right-8 md:-right-4 lg:bottom-1/3 lg:-right-10 xl:right-0">
            <span className="font-extrabold text-sm xl:text-base text-white bg-black py-1 xl:py-2 px-4 rounded-tr-3xl">
              MR. VIMAL PATEL
            </span>
            <span className="text-black text-sm xl:text-base font-description-semibold bg-yellow p-1 xl:p-2 pr-4">
              FOUNDER DIRECTOR
            </span>
          </div>
        </div>
        <p className="flex-1 font-medium self-center mt-8 md:mt-32 lg:mt-0 md:-ml-16 lg:ml-0 text-base xl:text-lg">
          Bringing a background in Hotel and Tourism Management, Vimal Patel is
          an IIM dropout who boasts over a decade of experience gained from
          renowned global organisations such as Bollants IM Park Hotel in
          Germany and Jubilant Food Works (Dominos Pizza). His profound passion
          for food production became the driving force behind the inception of
          The Fusion Pizza.
        </p>
      </div>
      <div className="w-full flex flex-col md:flex-row-reverse lg:flex-col gap-0 lg:gap-4">
        <div className="w-full md:w-2/3 lg:w-full relative flex justify-end md:-mr-8 lg:mr-0">
          <img
            src={BrijeshChauhan}
            alt="Brijesh Chauhan Co-Founder"
            className="w-4/5 object-contain"
          />
          <div className="flex flex-col justify-end  items-end absolute -bottom-2 md:bottom-72 lg:bottom-8 xl:bottom-12 left-2 md:-left-10 xl:left-0">
            <span className="font-extrabold text-sm xl:text-base text-white bg-black py-1 xl:py-2 px-4 rounded-tl-3xl">
              MR. BRIJESH CHAUHAN
            </span>
            <span className="text-black text-sm xl:text-base font-description-semibold bg-yellow p-1 xl:p-2 pr-4">
              DIRECTOR
            </span>
          </div>
        </div>
        <p className="flex-1 font-medium self-center mt-8 md:mt-36 lg:mt-0 md:-mr-16 lg:mr-0 text-base xl:text-lg">
          With over 15 years in the FMCG industry, Brijesh Chauhan excels in
          sales and marketing. His extensive roles at Rasna Private Ltd, ADF
          Food Ltd, and other notable organisations have finely tuned his skills
          in comprehending market needs and innovating recipes for healthy,
          quick-service foods.
        </p>
      </div>
      <div className="w-full flex flex-col md:flex-row lg:flex-col gap-0 lg:gap-4">
        <div className="w-full md:w-2/3 lg:w-full flex relative justify-start lg:justify-end md:-ml-8 lg:ml-0">
          <img
            src={VijethJain}
            alt="Vijeth Jain Co-Founder"
            className="w-3/4 object-contain"
          />
          <div className="flex flex-col justify-start items-start lg:items-end absolute bottom-2 md:bottom-80 right-8 md:right-4 lg:right-auto md:left-auto lg:bottom-12 lg:-left-10 xl:left-0">
            <span className="font-extrabold text-sm xl:text-base text-white bg-black py-1 xl:py-2 px-4 rounded-tr-3xl lg:rounded-tr-none lg:rounded-tl-3xl">
              MR. VIJETH JAIN
            </span>
            <span className="text-black text-sm xl:text-base font-description-semibold bg-yellow p-1 xl:p-2 pr-4">
              DIRECTOR
            </span>
          </div>
        </div>
        <p className="flex-1 font-medium self-center mt-8 md:mt-40 lg:mt-0 md:-ml-16 lg:ml-0 text-base xl:text-lg">
          Vijeth Jain, with an MBA in Finance and Marketing, has accumulated
          over 15 years of expertise in banking and franchising. As a seasoned
          professional, he has now taken on the role of a director to expand the
          company's reach. His vision involves not only establishing the brand
          in the pan-India market but also exploring opportunities in the
          international arena. This strategic move aims to maximize the
          organization's growth and success on a global scale.
        </p>
      </div>
    </section>
  );
};

export default AboutFounders;
