import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Logo_Desktop from "../assets/images/nav_logo.png";

const Navbar = () => {
  const location = useLocation();
  const [mobileMenu, setMobileMenu] = useState(false);
  const [stickyClass, setStickyClass] = useState(false);

  function stickNavbar() {
    let windowHeight = window.scrollY;
    windowHeight > 100 ? setStickyClass(true) : setStickyClass(false);
  }

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
  }, []);

  const routes = [
    {
      id: 1,
      link: "/",
      name: "Home",
    },
    {
      id: 2,
      link: "/about",
      name: "About Us",
    },
    {
      id: 3,
      link: "/franchise",
      name: "Franchise",
    },
    {
      id: 4,
      link: "/contact",
      name: "Contact Us",
    },
  ];
  const currentPage = routes.find((item) => item.link === location.pathname);

  return (
    <nav className="fixed z-50 w-screen">
      <div
        className={`flex items-center md:items-start ${location.pathname === "/franchise" || location.pathname === "/contact" ? (stickyClass ? "bg-black w-11/12 lg:w-10/12 justify-between px-4 md:px-0 rounded-b-3xl mx-auto" : "md:bg-black w-11/12 lg:w-10/12 justify-between px-4 md:px-0 rounded-b-3xl mx-auto") : stickyClass ? "bg-black px-4 md:px-0 rounded-b-3xl mx-auto w-11/12 lg:w-10/12 justify-between" : "px-4 md:px-8 lg:px-20 xl:px-36 justify-between"}`}
      >
        <img
          src={Logo_Desktop}
          alt="Logo"
          className="object-contain h-16 xl:h-20 py-2 md:pl-10 lg:pt-4 pr-8 lg:pr-16 xl:pr-32"
        />
        <div className="relative flex flex-col">
          <div className="md:hidden flex items-center gap-4">
            <span className="text-white uppercase font-description-semibold text-sm ">
              {currentPage.name}
            </span>
            <button
              onClick={() => setMobileMenu(!mobileMenu)}
              className="w-6 flex flex-col justify-between gap-1"
            >
              <span className="w-full h-1 bg-white rounded-full" />
              <span className="w-full h-1 bg-white rounded-full" />
              <span className="w-full h-1 bg-white rounded-full" />
            </button>
          </div>
          <ul
            className={`${mobileMenu ? "flex" : "hidden"} bg-black pl-10 pr-6 py-4 flex-col absolute right-0 top-12 text-white font-description-bold  uppercase text-sm justify-center gap-12 flex-1 items-end rounded-3xl rounded-tr-none`}
          >
            {routes.map((route) => {
              return (
                <li
                  key={route.id}
                  className="flex flex-col justify-center relative"
                >
                  <NavLink
                    to={route.link}
                    onClick={() => setMobileMenu(!mobileMenu)}
                    className={({ isActive, isPending }) =>
                      isPending
                        ? ""
                        : isActive && route.name !== "Home"
                          ? "text-yellow"
                          : "hover:text-yellow flex flex-col whitespace-nowrap"
                    }
                  >
                    {route.name}
                  </NavLink>

                  <span
                    className={`absolute w-2/4 -bottom-3/4 right-0 h-0.5 bg-purple my-2 rounded-full ${location.pathname === route.link ? "flex" : "hidden"}`}
                  />
                </li>
              );
            })}
          </ul>
        </div>
        <ul
          className={`${stickyClass ? "bg-transparent gap-8 lg:gap-16 xl:gap-28" : "bg-black gap-12 lg:gap-16 xl:gap-28"}  px-8 pt-4 pb-6 text-white  hidden h-16 xl:h-20 md:flex uppercase text-sm justify-center flex-1 items-center rounded-b-3xl`}
        >
          {routes.map((route) => {
            return (
              <li
                key={route.id}
                className="flex flex-col justify-center relative"
              >
                <NavLink
                  to={route.link}
                  className={({ isActive, isPending }) =>
                    isPending
                      ? ""
                      : isActive && route.name !== "Home"
                        ? "text-yellow font-description-semibold"
                        : "hover:text-yellow flex flex-col font-description-semibold"
                  }
                >
                  {route.name}
                </NavLink>

                <span
                  className={`absolute w-2/4 -bottom-3/4 h-0.5 bg-purple my-2 rounded-full ${location.pathname === route.link ? "flex" : "hidden"}`}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
