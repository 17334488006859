import React from "react";
import FrnachiseMan from "../assets/images/home/home_franchise_man.png";
import FrnachiseManMobile from "../assets/images/home/franchiseManMobile.png";
import MiddleStarHalf from "../assets/images/MiddleStarHalf.png";
import BigStarHalf from "../assets/images/BigStarHalf.png";
import LittleStar from "../assets/images/LittleStar.png";

const Franchise = () => {
  return (
    <section className="py-16 px-4 md:px-8 lg:px-20 xl:px-36 flex justify-center flex-col lg:flex-row items-center relative">
      <img
        src={MiddleStarHalf}
        alt="Middle Star"
        className="absolute object-contain top-8 md:top-48 right-0 w-6 md:w-8 lg:w-10 xl:w-auto"
      />
      <img
        src={BigStarHalf}
        alt="Big Star"
        className="absolute object-contain left-0 -bottom-20 w-14 hidden lg:flex"
      />
      <img
        src={LittleStar}
        alt="Little Star"
        className="absolute flex lg:hidden -left-2 md:-left-3 w-8 md:w-12 top-16 md:top-44 "
      />
      <img
        src={LittleStar}
        alt="Little Star"
        className="absolute flex lg:hidden left-12 md:left-24 w-8 md:w-12 bottom-44 "
      />
      <div className="w-full relative">
        <img
          src={FrnachiseMan}
          alt="Franchise Man Poster"
          className="w-full object-contain hidden lg:flex"
        />
      </div>
      <div className="relative flex flex-col w-full gap-12 items-end lg:-ml-16 xl:-ml-24">
        <div className="relative flex flex-col lg:w-5/6 gap-2">
          <h3 className="font-title text-purple text-5xl md:text-6xl text-center lg:text-left">
            FRANCHISE
          </h3>
          <p className="text-base md:text-lg font-medium text-center lg:text-left px-4 md:px-8 lg:px-0">
            The Big Burger Co. franchise model targets the real -time gap in the
            market, addressing it by offering innovative solutions, leveraging a
            proven track record, aligning with market demands and growth
            potential, and fostering a strong franchisor - franchisee
            relationship - paving a reliable path toward the success you seek.
          </p>
        </div>
        <div className="relative flex flex-col w-full gap-6 items-end lg:items-start">
          <div className="relative w-full flex ">
            <img
              src={FrnachiseManMobile}
              alt="Franchise Man Poster"
              className="object-contain w-11/12 flex lg:hidden z-10 -ml-8"
            />
            <img
              src={LittleStar}
              alt="Little Star"
              className="object-contain absolute right-8 md:right-10 w-12 md:w-20 top-24 md:top-40"
            />
            <img
              src={LittleStar}
              alt="Little Star"
              className="object-contain absolute right-2 md:right-4 w-6 md:w-10 top-32 md:top-52"
            />
            <span className="absolute bottom-8 md:bottom-20 right-0 flex flex-col items-end w-full lg:hidden">
              <span className="font-title text-xl md:text-5xl px-4 md:px-8 py-1 md:py-2 rounded-full bg-purple text-white -mb-2 z-10">
                HOW?
              </span>
              <span className="font-title pl-24 pr-4 md:pr-8 py-2 md:py-3 text-base md:text-3xl rounded-r-full bg-black text-white">
                SMART QUESTION!
              </span>
            </span>
          </div>
          <span className="hidden lg:flex items-center w-full">
            <span className="font-title text-xl md:text-4xl px-6 py-2 rounded-full bg-purple text-white z-10">
              HOW?
            </span>
            <span className="font-title px-6 py-1 text-sm md:text-2xl rounded-r-full bg-black text-white absolute left-20 md:left-28">
              SMART QUESTION!
            </span>
          </span>
          <p className="text-base md:text-lg font-medium text-right w-2/3 lg:w-full lg:text-left">
            We leverage what we know best - excellent delivery ratings on third-
            party apps like Swiggy & Zomato, quick, no-delay deliveries, a wide
            array of choices, and compelling deals - making our actions 100%
            fruitful. But that’s just the tip of the iceberg. Our franchise goes
            above and beyond, offering industry support, guidance to franchise
            owners, and thorough costing ideas, all of which reflect in our
            positive numbers.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Franchise;
