import React from "react";
import Facebook from "../assets/images/footer_fb.png";
import Instagram from "../assets/images/footer_insta.png";

const Footer = () => {
  return (
    <footer className="flex justify-between items-center bg-black text-white py-2 px-4 md:px-8 lg:px-20 xl:px-36">
      <p className="text-sm md:text-sm">
        Copyright © 2024 Big Burger Co. | All Rights Reserved.
      </p>
      <div className="flex gap-8">
        <a
          href="https://www.facebook.com/profile.php?id=100095509118430&mibextid=ZbWKwL"
          target="_blank"
        >
          <img
            src={Facebook}
            alt="Facebook"
            className="h-10 md:h-6 object-contain"
          />
        </a>
        <a
          href="https://www.instagram.com/bigburgerco.official?igsh=YmhoaHc2Mnl0d2R2"
          target="_blank"
        >
          <img
            src={Instagram}
            alt="Instagram"
            className="h-10 md:h-6 object-contain"
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
