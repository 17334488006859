import React from "react";
import MarketNeed from "../assets/images/franchise/market_needs.png";
import MarketNeedsCarousel from "../components/MarketNeedsCarousel";

const MarketNeeds = () => {
  const data = [
    {
      title: "NOW IT’S H2H",
      desc: "Shifting our approach to Human to Human & prioritising authentic customer connections is the foundation of BBC.",
    },
    {
      title: "CUSTOMER SATISFACTION",
      desc: "Customer feedback in line with keeping our menu consistent with the wants & choices of the market, ensuring high satisfaction levels.",
    },
    {
      title: "SUPPORT & TRAINING",
      desc: "Comprehensive training, ongoing support, and access to resources necessary to maintain our brand standards and quality",
    },
    {
      title: "RESULT ORIENTED",
      desc: "Focused on tangible outcomes, strategies aimed at achieving measurable and impactful results",
    },
  ];
  return (
    <section className="flex -mt-14 lg:mt-0 lg:pt-10 lg:pb-20 flex-col lg:flex-row">
      <img
        src={MarketNeed}
        alt="Market Need Image"
        className="w-2/3 lg:w-1/2 object-contain self-center z-10"
      />
      <div className="flex flex-col justify-center items-center lg:items-start gap-4 lg:w-1/2 h-full py-12 lg:py-24 pl-4 md:pl-8 xl:pl-16 pr-4 md:pr-8 lg:pr-20 xl:pr-36">
        <p className="font-title text-purple text-4xl md:text-5xl xl:text-6xl text-center lg:text-left max-w-lg">
          We are the difference the market needs
        </p>
        <span className="bg-purple text-white font-title text-2xl md:text-3xl xl:text-4xl py-0.5 md:py-1 xl:py-2 px-4 md:px-6 xl:px-8 rounded-full">
          HOW?
        </span>
        <p className="font-description-bolditalic text-black text-xl xl:text-3xl -mt-6 lg:mt-0 pt-0 xl:pt-12 line-through text-left w-full">
          B2B & B2C
        </p>
        <MarketNeedsCarousel data={data} />
      </div>
    </section>
  );
};

export default MarketNeeds;
