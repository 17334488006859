import React, { useEffect, useState } from "react";
import pause from "../assets/images/franchise/pause.png";
import play from "../assets/images/franchise/play.png";
import video from "../assets/videos/BBC_franchise.mp4";

const FranchiseBanner = () => {
  const [videoPlay, setVideoPlay] = useState(false);
  useEffect(() => {
    let banner_video = document.getElementById("video");
    if (banner_video.pause()) {
      setVideoPlay(false);
    }
  }, [setVideoPlay]);
  return (
    <section className="relative flex w-full z-10 justify-center items-center">
      {!videoPlay && (
        <button
          className="absolute z-10"
          onClick={() => {
            let banner_video = document.getElementById("video");
            banner_video.play();
            setVideoPlay(true);
          }}
        >
          <img src={play} alt="play button" />
        </button>
      )}
      {videoPlay && (
        <button
          className="absolute z-10"
          onClick={() => {
            let banner_video = document.getElementById("video");
            banner_video.pause();
            setVideoPlay(false);
          }}
        >
          <img src={pause} alt="play button" />
        </button>
      )}
      <video className="-mb-12 md:rounded-b-3xl" src={video} id="video" />
    </section>
  );
};

export default FranchiseBanner;
