import React, { useEffect } from "react";
import WhyTakeFranchise from "../franchiseContainers/WhyTakeFranchise";
import InquiryForm from "../franchiseContainers/InquiryForm";
import FranchiseBanner from "../franchiseContainers/franchiseBanner";
import Testimonial from "../franchiseContainers/Testimonial";
import MarketNeeds from "../franchiseContainers/MarketNeeds";
import ReportCard from "../franchiseContainers/ReportCard";
import { useLocation } from "react-router-dom";

const Franchise = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <FranchiseBanner />
      <WhyTakeFranchise />
      <InquiryForm />
      <MarketNeeds />
      <ReportCard />
      <Testimonial />
    </>
  );
};

export default Franchise;
