import React from "react";
import Review from "../assets/images/franchise/review.png";
import "../index.css";

const TestimonialCard = (props) => {
  const { review } = props;
  return (
    <div className="flex relative">
      <img
        src={Review}
        alt="Testimonial Card"
        className="w-11/12 md:w-3/4 xl:w-10/12 object-contain"
      />
      <div className="absolute flex justify-between flex-col top-0 bottom-0 left-0 w-3/4 xl:w-10/12 px-4 md:px-12 pt-4 md:pt-8">
        <p className="text-black text-sm md:text-xl line-clamp">
          {review.desc}
        </p>
        <span className="font-title text-black text-2xl md:text-4xl h-2/5">
          {review.name}
        </span>
      </div>
    </div>
  );
};

export default TestimonialCard;
