import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import MenuArrowLeft from "../assets/images/home/MenuArrowLeft.png";
import MenuArrowRight from "../assets/images/home/MenuArrowRight.png";

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="flex w-full justify-between items-center gap-8 h-24 absolute top-0 md:top-1/2">
      <button onClick={() => previous()} className="z-50 ">
        <img
          src={MenuArrowRight}
          alt="Left Arrow"
          className="w-8 md:w-10 xl:w-12 object-contain"
        />
      </button>

      <button onClick={() => next()} className="z-50">
        <img
          src={MenuArrowLeft}
          alt="Left Arrow"
          className="w-8 md:w-10 xl:w-12 object-contain"
        />
      </button>
    </div>
  );
};

const MenuCarouasel = (props) => {
  const { data } = props;
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const menu = data?.data ?? [];

  const uniqueCategories = [...new Set(menu.map((item) => item.category_val))];

  const filterByCategory = (array, category) => {
    return array.filter((item) => item.category_val === category);
  };

  const combinedArray = uniqueCategories.map((category) => {
    const filteredItems = filterByCategory(menu, category);
    return { category, filteredItems };
  });
  return (
    <div className="relative flex flex-col w-full h-full">
      <Carousel
        className="relative py-4 lg:py-12 px-4 md:px-16"
        additionalTransfrom={0}
        arrows={false}
        autoPlaySpeed={3000}
        centerMode={false}
        containerClass="flex "
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={true}
        renderDotsOutside={false}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
        responsive={responsive}
        customButtonGroup={<ButtonGroup data={data} />}
      >
        {combinedArray.map((menu, id) => {
          return (
            <div key={id} className="flex gap-2 flex-col w-11/12 md:w-10/12">
              <p className="text-yellow text-3xl md:text-5xl xl:text-6xl font-description-bold text-center md:text-left h-24 flex justify-center md:justify-start items-center md:items-start pb-8 md:pb-0 md:mb-0 xl:mb-12 px-8 md:px-0">
                {menu.category}
              </p>
              <div className="flex flex-col gap-8">
                <div key={id} className="flex justify-between">
                  <div className="flex flex-col flex-1"></div>
                  <div className="flex px-2 md:px-4 gap-2">
                    <p className="bg-yellow text-center font-description-semibold text-black w-20 text-xs overflow-ellipsis flex justify-center">
                      {/* REGULAR */}
                    </p>
                    {/* <p className="bg-yellow text-center font-description-semibold text-black w-8 md:w-20 text-xs overflow-ellipsis flex justify-center">
                      M<span className="hidden md:flex">ULTIGRAIN</span>
                    </p>
                    <p className="bg-yellow text-center font-description-semibold text-black w-8 md:w-20 text-xs overflow-ellipsis">
                      BB
                    </p> */}
                  </div>
                </div>
                {menu.filteredItems.map((item, id) => {
                  return (
                    <div key={id} className="flex justify-between">
                      <div className="flex flex-col flex-1">
                        <p className="text-yellow text-sm md:text-2xl">
                          {item.name}
                        </p>
                        <p className="md:max-w-sm text-sm md:text-base text-white">
                          {item.description}
                        </p>
                      </div>
                      <div className="flex px-2 md:px-4 gap-2">
                        {/* <p className="text-yellow w-20 text-sm md:text-2xl text-center">
                          {item.mrp}
                        </p> */}
                        {/* <p className="text-yellow w-8 md:w-20 text-sm md:text-2xl">
                          {item.multigrain}
                        </p>
                        <p className="text-yellow w-8 md:w-20 text-sm md:text-2xl">
                          {item.bb}
                        </p> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default MenuCarouasel;
