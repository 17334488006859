import React from "react";
import BgImage from "../assets/images/about/vission_mission_bg.png";
import Element from "../assets/images/about/about_page_element.png";
import VisionBack from "../assets/images/about/VisionBack.png";
import MissionBack from "../assets/images/about/MissionBack.png";
import BigStarHalf from "../assets/images/BigStarHalf.png";
import MiddleStarHalf from "../assets/images/MiddleStarHalf.png";
import LittleStar from "../assets/images/LittleStar.png";

const VissionMission = () => {
  return (
    <>
      <section
        className="-mt-4 mb-16 py-16 mx-20 xl:mx-36 relative hidden lg:flex px-8 gap-8 bg-no-repeat bg-center bg-cover rounded-3xl"
        style={{ backgroundImage: `url(${BgImage})` }}
      >
        <img
          src={Element}
          alt="Element"
          className="absolute left-1/3 -bottom-12 w-28 lg:w-36"
        />
        <div className="relative flex flex-1 flex-col gap-6 items-baseline justify-start">
          <h2 className="font-title text-white text-3xl lg:text-5xl bg-black rounded-2xl py-2 px-4">
            VISION
          </h2>
          <div className="flex flex-col gap-0">
            <span className="font-description-semibolditalic text-base lg:text-xl">
              Innovative Evolution:
            </span>
            <p className="font-medium text-base lg:text-xl">
              Evolving continuously in response to market dynamics & consumer
              preferences, leveraging innovation to redefine the burger
              experience.
            </p>
          </div>
          <div className="flex flex-col gap-0">
            <span className="font-description-semibolditalic text-base lg:text-xl">
              Sustainable Expansion:
            </span>
            <p className="font-medium text-base lg:text-xl">
              Strategically expanding our footprint while upholding sustainable
              practices, ensuring a balanced growth trajectory over the years.
            </p>
          </div>
          <div className="flex flex-col gap-0">
            <span className="font-description-semibolditalic text-base lg:text-xl">
              Global Recognition:
            </span>
            <p className="font-medium text-base lg:text-xl">
              Making BBC a household name, known for its quality, taste, and
              unparalleled customer satisfaction through meticulous expansion &
              operational excellence.
            </p>
          </div>
        </div>
        <div className="relative flex flex-1 flex-col gap-6 items-baseline justify-start">
          <h2 className="font-title text-white text-3xl lg:text-5xl bg-black rounded-2xl py-2 px-8">
            MISSION
          </h2>
          <div className="flex flex-col gap-0">
            <span className="font-description-semibolditalic text-base lg:text-xl">
              Precision in Execution:
            </span>
            <p className="font-medium text-base lg:text-xl">
              Executing our operations with precision, leveraging data-driven
              insights to optimise processes & consistently deliver exceptional
              products and services.
            </p>
          </div>
          <div className="flex flex-col gap-0">
            <span className="font-description-semibolditalic text-base lg:text-xl">
              Collaborative Growth:
            </span>
            <p className="font-medium text-base lg:text-xl">
              Cultivating robust partnerships & collaborations that foster
              growth opportunities for both the brand & our franchise partners,
              creating a mutually beneficial ecosystem
            </p>
          </div>
          <div className="flex flex-col gap-0">
            <span className="font-medium italic text-base lg:text-xl">
              Customer-Centric Excellence:
            </span>
            <p className="font-medium text-base lg:text-xl">
              Placing customer satisfaction at the core of our operations,
              constantly innovating to exceed expectations, and ensuring an
              unparalleled dining experience that resonates with our patrons
              worldwide
            </p>
          </div>
        </div>
      </section>
      <section className="relative flex flex-col lg:hidden px-8 gap-8 pt-8 -mb-10 md:-mb-14">
        <img
          src={BigStarHalf}
          alt="Star"
          className="absolute object-contain left-0 bottom-44 w-4 md:w-8"
        />
        <img
          src={MiddleStarHalf}
          alt="Star"
          className="absolute object-contain right-0 top-44 w-6 md:w-10"
        />
        <img
          src={LittleStar}
          alt="Star"
          className="absolute object-contain right-4 md:right-24 top-1/2 md:top-96 w-6"
        />
        <div className="relative flex flex-col gap-6 items-baseline justify-start p-4 md:w-3/4 pt-16 md:pt-12 pb-20">
          <img
            src={Element}
            alt="Element"
            className="absolute left-4 md:left-1/4 -bottom-14 md:-bottom-16 w-24 md:w-28"
          />
          <img
            src={VisionBack}
            alt="Vision Background"
            className="flex w-full h-full absolute top-0 left-0"
            style={{ zIndex: "-1" }}
          />
          <h2 className="font-title text-white text-4xl bg-black rounded-2xl py-2 px-8 absolute -top-6 left-10">
            VISION
          </h2>

          <p className="font-medium text-base">
            <span className="font-description-semibolditalic text-base">
              Innovative Evolution:{" "}
            </span>
            Evolving continuously in response to market dynamics & consumer
            preferences, leveraging innovation to redefine the burger
            experience.
          </p>

          <p className="font-medium text-base">
            <span className="font-description-semibolditalic text-base">
              Sustainable Expansion:{" "}
            </span>
            Strategically expanding our footprint while upholding sustainable
            practices, ensuring a balanced growth trajectory over the years.
          </p>

          <p className="font-medium text-base">
            <span className="font-description-semibolditalic text-base">
              Global Recognition:{" "}
            </span>
            Making BBC a household name, known for its quality, taste, and
            unparalleled customer satisfaction through meticulous expansion &
            operational excellence.
          </p>
        </div>
        <div className="relative flex flex-col gap-6 items-baseline justify-start p-4 md:w-3/4 self-end py-16 md:py-10">
          <img
            src={MissionBack}
            alt="Mission Background"
            className="flex w-full h-full absolute top-0 left-0"
            style={{ zIndex: "-1" }}
          />
          <h2 className="font-title text-white text-4xl bg-black rounded-2xl py-2 px-4 absolute -top-6 right-10">
            MISSION
          </h2>
          <div className="flex flex-col gap-0 text-right">
            <span className="font-description-semibolditalic text-base">
              Precision in Execution:{" "}
            </span>
            <p className="font-medium text-base">
              Executing our operations with precision, leveraging data-driven
              insights to optimise processes & consistently deliver exceptional
              products and services.
            </p>
          </div>
          <div className="flex flex-col gap-0 text-right">
            <span className="font-description-semibolditalic text-base">
              Collaborative Growth:{" "}
            </span>
            <p className="font-medium text-base">
              Cultivating robust partnerships & collaborations that foster
              growth opportunities for both the brand & our franchise partners,
              creating a mutually beneficial ecosystem
            </p>
          </div>
          <div className="flex flex-col gap-0 text-right">
            <span className="font-description-semibolditalic italic text-base">
              Customer-Centric Excellence:{" "}
            </span>
            <p className="font-medium text-base">
              Placing customer satisfaction at the core of our operations,
              constantly innovating to exceed expectations, and ensuring an
              unparalleled dining experience that resonates with our patrons
              worldwide
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default VissionMission;
