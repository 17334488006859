import React from "react";
import BBBangCard from "../assets/images/home/BBBangCard.png";
import PurpleCurve from "../assets/images/home/PurpleCurve.png";
import LittleStar from "../assets/images/LittleStar.png";
import BigStarHalf from "../assets/images/BigStarHalf.png";
import MiddleStarHalf from "../assets/images/MiddleStarHalf.png";
import ChawPowder from "../assets/images/home/ChawPowder.png";
import OnFire from "../assets/images/home/OnFire.png";
import CardMobile from "../assets/images/home/BBCardMobile.png";
import Burger from "../assets/images/home/BBCardBurger.png";

const BBBang = () => {
  return (
    <section className="bg-white relative flex flex-col pb-4 md:px-8 lg:px-20 xl:px-36 pt-10 md:pt-0">
      <img
        src={BigStarHalf}
        alt="Big Star"
        className="absolute left-0 top-0 w-8 lg:w-14 object-contain hidden md:flex"
      />
      <img
        src={LittleStar}
        alt="Little Star"
        className="absolute left-4 top-1/2 w-6 flex md:hidden"
      />
      <img
        src={MiddleStarHalf}
        alt="Mid Star"
        className="absolute right-0 bottom-60 w-6 lg:w-12 object-contain"
      />

      <div className="flex relative mb-16 md:mb-12 -mt-4 md:-mt-9 lg:-mt-12 xl:-mt-20 px-4 md:px-0">
        <img
          src={BBBangCard}
          alt="BBBangCard with Burger and Drink"
          className="w-full object-contain hidden md:flex"
        />
        <img
          src={CardMobile}
          alt="BBBangCard with Burger and Drink"
          className="w-full object-contain flex md:hidden"
        />
        <p className="flex md:hidden font-title text-white text-3xl absolute top-1/3 text-center right-6 tracking-wide">
          'Tis the big juicy love <br /> story of the century
        </p>
        <img
          src={Burger}
          alt="BBBangCard with Burger and Drink"
          className="object-contain flex md:hidden absolute w-2/3 -bottom-6"
        />
      </div>
      <div className="flex justify-center relative items-center w-full flex-col">
        <img
          src={LittleStar}
          alt="Little Star"
          className="absolute left-8 md:left-4 bottom-1/3 md:bottom-12 xl:left-32 xl:bottom-20 w-6 md:w-4 xl:w-auto"
        />
        <img
          src={ChawPowder}
          alt="Chaw Powder"
          className="absolute left-4 md:left-20 2xl:left-40 -bottom-20 md:-bottom-20 2xl:-bottom-28 w-24 md:w-36 2xl:w-44 object-contain"
        />
        <img
          src={OnFire}
          alt="On Fire"
          className="absolute right-8 md:right-0 -bottom-16 -mb-2 md:mb-0 md:-bottom-10 w-16 md:w-28 xl:w-32 object-contain z-50"
        />
        <div className="w-full flex md:gap-8 justify-center max-w-5xl overflow-hidden">
          <img
            src={PurpleCurve}
            alt="Purple curve line"
            className="object-contain md:w-full"
          />
          <h2 className="w-full text-center whitespace-nowrap font-title uppercase text-3xl md:text-5xl lg:text-6xl text-purple pb-4">
            THE BIG BURGER BANG
          </h2>
          <img
            src={PurpleCurve}
            alt="Purple curve line"
            className="object-contain md:w-full"
          />
        </div>
        <p className="text-center text-sm md:text-lg xl:text-2xl font-description-semibold pb-8 max-w-7xl px-8 md:px-0">
          The success story of Fusion Pizza transitioned into the world of Big
          Burger Company, delivering a delectable range - you know what - juicy,
          fiery, and utterly appetising burgers, toasting the essence of{" "}
          <span className="font-description-bolditalic">'MAKE IN INDIA'</span>{" "}
          in the mix of fast-food giants. We cater not only to the evolving
          market demands but also to customer desires and expectations.
        </p>
        <p className="text-center text-sm md:text-lg xl:text-2xl font-description-semibold pb-4 max-w-3xl px-8 md:px-0">
          Within just{" "}
          <span className="font-description-bolditalic">4 MONTHS</span> since
          our operations began, we've served over{" "}
          <span className="font-description-bolditalic">100,000+ BURGERS.</span>
        </p>
        <span className="font-title uppercase text-3xl md:text-4xl text-purple pb-4">
          NEED WE SAY MORE?
        </span>
      </div>
    </section>
  );
};

export default BBBang;
