import React from "react";
import Smiley from "../assets/images/franchise/smiley.png";
import Thumb from "../assets/images/franchise/thumb.png";
import Efficiency from "../assets/images/franchise/efficiency.png";
import WhiteDivider from "../assets/images/franchise/white_divider.png";
import Star from "../assets/images/LittleStar.png";

const WhyTakeFranchise = () => {
  return (
    <section
      className={`relative flex flex-col items-center w-full bg-no-repeat bg-center bg-cover min-h-screen h-full px-4 md:px-16 lg:px-20 xl:px-36 pt-32 lg:pb-96 overflow-x-hidden bg-franchise-back-mobile lg:bg-franchise-back`}
      style={{
        borderBottomLeftRadius: "6rem",
        borderBottomRightRadius: "6rem",
      }}
    >
      <img
        src={Star}
        alt="Star 1"
        className="absolute object-contain w-4 md:w-8 lg:w-6 left-8 md:left-20 lg:left-60 2xl:left-80 top-60 lg:top-44"
      />
      <img
        src={Star}
        alt="Star 2"
        className="absolute object-contain w-8 md:w-10 right-8 md:right-20 lg:right-80 top-20 lg:top-14"
      />
      <img
        src={Star}
        alt="Star 3"
        className="absolute object-contain w-4 left-4 xl:left-64 bottom-96 md:bottom-80 lg:bottom-auto top-auto lg:top-1/2"
      />

      <h1 className="text-center font-title text-yellow text-5xl md:text-6xl xl:text-8xl">
        Big Burger Co.
      </h1>
      <p className="text-white py-2 text-sm md:text-lg xl:text-2xl text-center max-w-3xl">
        IS PASSIONATE ABOUT DISHING OUT MORE THAN JUST THE SIGNATURE BIG BURGERS
        YOU FANTASISE ABOUT.
      </p>
      <img
        src={WhiteDivider}
        alt="White Divider"
        className="object-contain my-1 md:my-2 xl:my-4 w-24 xl:w-28"
      />
      <h2 className="py-2 text-center font-title text-yellow text-2xl md:text-4xl xl:text-6xl">
        WE’RE ABOUT CREATING A FRANCHISE OPPORTUNITY THAT’S EQUAL PARTS
        <span className="text-white font-title"> COMPELLING </span> AND
        <span className="text-white font-title"> REWARDING</span>
      </h2>
      <p className="text-white py-2 text-sm md:text-lg xl:text-2xl text-center max-w-6xl">
        SO IF YOU’RE A MAN ON A MISSION LOOKING TO INVEST IN A FAST-FOOD
        FRANCHISE, HERE’S WHY YOU SHOULD CONSIDER BEING A PART OF OUR UNIVERSE
      </p>
      <div className="relative flex gap-4 md:gap-12 lg:gap-32 pt-8 justify-center h-72 lg:h-auto">
        <img
          src={Star}
          alt="Star 4"
          className="absolute object-contain w-6 md:w-10 lg:w-6 right-16 md:right-28 lg:right-auto left-auto lg:left-1/3 top-2 lg:top-auto lg:bottom-8"
        />
        <img
          src={Star}
          alt="Star 5"
          className="absolute object-contain w-12 md:w-20 lg:w-6 -right-20 md:-right-1/3 lg:-right-24 xl:-right-1/4 bottom-0"
        />
        <div className="flex flex-col gap-4 justify-center items-center">
          <img
            src={Smiley}
            alt="Smiley"
            className="h-16 md:h-28 xl:h-32 object-contain"
          />
          <span className="text-center font-title text-white text-2xl md:text-4xl xl:text-5xl">
            Consistency <br /> & Quality
          </span>
        </div>
        <div className="flex flex-col gap-4 lg:justify-center items-center">
          <img
            src={Thumb}
            alt="Thumb"
            className="h-16 md:h-28 xl:h-32 object-contain"
          />
          <span className="text-center font-title text-white text-2xl md:text-4xl xl:text-5xl">
            Brand <br /> Identity
          </span>
        </div>
        <div className="flex flex-col gap-4 justify-center items-center">
          <img
            src={Efficiency}
            alt="Efficiency"
            className="h-16 md:h-28 xl:h-32 object-contain"
          />
          <span className="text-center font-title text-white text-2xl md:text-4xl xl:text-5xl">
            Efficiency <br /> & Speed
          </span>
        </div>
      </div>
    </section>
  );
};

export default WhyTakeFranchise;
