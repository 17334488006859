import React, { useCallback, useEffect } from "react";
import Name from "../assets/images/contact/ContactForm1.png";
import Call from "../assets/images/contact/ContactForm2.png";
import Mail from "../assets/images/contact/ContactForm3.png";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import useBigBurger from "../../src/data/talons/BigBurger/useBigBurger";
import { resetInquiryFormData } from "../../src/data/reducers/bigBurger.reducer";
import Telephone from "../assets/images/contact/telephone.png";
import Phone from "../assets/images/contact/phone.png";
import MailBlack from "../assets/images/contact/mail.png";
import Address from "../assets/images/contact/address.png";
import Insta from "../assets/images/contact/insta.png";
import Facebook from "../assets/images/contact/facebook.png";

const ContactUsContainer = () => {
  const { inquiryEntrySchema, ContactEntry, alert, setAlert } = useBigBurger();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setValue,
  } = useForm({
    resolver: joiResolver(inquiryEntrySchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      contact_num: "",
      email_id: "",
    },
  });

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 10));
  };

  const onUserSubmit = useCallback(async () => {
    try {
      const res = await ContactEntry({
        ...getValues(),
      });
      if (res == true) {
        setAlert(true);
        reset();
        dispatch(resetInquiryFormData());
        setValue("");
      }
    } catch (err) {
      console.log("errr", err);
    }
  }, [ContactEntry]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert(false);
    }, 3000);

    // To clear or cancel a timer, you call the clearTimeout(); method,
    // passing in the timer object that you created into clearTimeout().

    return () => clearTimeout(timer);
  }, [alert]);

  const dispatch = useDispatch();
  return (
    <section className="relative flex flex-col lg:flex-row min-h-screen h-full bg-cover bg-ContactBG -mt-8 pt-24 md:pt-44 pb-24 md:px-8 lg:px-20 xl:px-36 gap-8">
      <div className="relative w-full flex-1 self-center flex flex-col gap-8 px-4 md:px-0">
        <img
          src={Telephone}
          alt="Telephone"
          className="object-contain absolute -top-16 md:-top-28 lg:-top-28 xl:-top-44 right-0 md:-right-8 w-1/2 md:w-2/3 lg:w-4/5"
        />
        <h2 className="font-title text-purple text-5xl md:text-6xl">
          Let's connect
        </h2>
        <p className="text-black font-description-semibold max-w-xs md:max-w-xl xl:max-w-lg">
          What you feel about us matters a lot! Please share your experience of
          Big Burger Co. with us. Your feedback or suggestions for improvement
          are highly appreciated.
        </p>
        <div className="flex gap-4 flex-col px-8 md:max-w-xl xl:max-w-lg">
          <div className="flex gap-4">
            <img
              src={Phone}
              alt="Phone Number"
              className="object-contain w-4"
            />
            <span className="text-black font-description-semibold">
              <a href="tel:+91 7046230003">+91 7046230003</a>
            </span>
          </div>
          <a
            className="flex gap-4"
            href="mailto:info@bigburger.co.in"
            target="_blank"
          >
            <img src={MailBlack} alt="Mail ID" className="object-contain w-4" />
            <span className="text-black font-description-semibold">
              Info@bigburger.co.in
            </span>
          </a>
          <a
            className="flex gap-4"
            href="https://maps.app.goo.gl/oLJeW1gComo6MNMP7"
            target="_blank"
          >
            <img
              src={Address}
              alt="Address"
              className="object-contain w-4 self-start mt-1"
            />
            <span className="text-black font-description-semibold">
              Shop No - 12, Ground floor, Town plaza, Near Raspan cross road,
              Nikol, Ahmedabad
            </span>
          </a>
        </div>
        <div className="flex flex-col gap-4">
          <h3 className="font-title text-purple text-4xl md:text-5xl">
            Get Social with us
          </h3>
          <div className="flex gap-4">
            <a
              href="https://www.instagram.com/bigburgerco.official?igsh=YmhoaHc2Mnl0d2R2"
              target="_blank"
            >
              <img
                src={Insta}
                alt="Instagram"
                className="object-contain h-12"
              />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100095509118430&mibextid=ZbWKwL"
              target="_blank"
            >
              <img
                src={Facebook}
                alt="Facebook"
                className="object-contain h-12"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="relative flex flex-col w-full flex-1">
        <span className="text-black font-description-semibold text-base text-right md:pb-2">
          For general and business inquiries
        </span>

        <form className="flex flex-col gap-8 w-full px-8 md:px-12 xl:px-16 pt-8 md:pt-12 xl:pt-16 pb-20 bg-no-repeat bg-cover h-full bg-center bg-ContactFormBG md:rounded-3xl">
          <div className="flex flex-col gap-2">
            <label className="text-white">Your Name</label>
            <div className="bg-white p-2 md:p-4 flex w-full gap-4">
              <img
                src={Name}
                alt="Name"
                className="object-contain w-4 md:w-6"
              />
              <input
                type="text"
                className="w-full bg-transparent focus:outline-none"
                {...register("name")}
                autoComplete="false"
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-white">Mail</label>
            <div className="bg-white p-2 md:p-4 flex w-full gap-4">
              <img
                src={Mail}
                alt="Mail"
                className="object-contain w-6 md:w-8"
              />
              <input
                type="email"
                className="w-full bg-transparent focus:outline-none"
                {...register("email_id")}
                autoComplete="false"
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-white">Phone</label>
            <div className="bg-white p-2 md:p-4 flex w-full gap-4">
              <img
                src={Call}
                alt="Call"
                className="object-contain w-6 md:w-8"
              />
              <input
                type="tel"
                className="w-full bg-transparent focus:outline-none"
                {...register("contact_num")}
                autoComplete="false"
                onInput={(e) => onlyNumbers(e)}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-white">Message</label>
            <div className="flex w-full gap-4">
              <textarea
                cols="30"
                rows="10"
                placeholder="Type your Message"
                className="p-4 w-full focus:outline-none"
                {...register("message")}
                autoComplete="false"
              />
            </div>
          </div>
          {alert === true ? (
            <div className="fade_in" style={{ color: "white" }}>
              Thank You For Showing Interest!
            </div>
          ) : null}
          <button
            className="bg-black text-white font-description-semibold px-8 md:px-12 py-4 text-sm md:text-base rounded-xl self-center absolute -bottom-6 z-10"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(onUserSubmit());
            }}
          >
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactUsContainer;
