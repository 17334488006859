import React, { useCallback, useEffect } from "react";
import GalleryCarousel from "../components/GalleryCarousel";
import FormElement1 from "../assets/images/home/home_inquiry_element.png";
import FormElement2 from "../assets/images/home/home_inquiry_element2.png";
import Item1 from "../assets/images/galler_1.png";
import Item2 from "../assets/images/galler_2.png";
import Item3 from "../assets/images/galler_3.png";
import Item4 from "../assets/images/galler_4.png";
import Item5 from "../assets/images/galler_5.png";
import Item6 from "../assets/images/galler_6.png";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import useBigBurger from "../../src/data/talons/BigBurger/useBigBurger";
import { resetInquiryFormData } from "../../src/data/reducers/bigBurger.reducer";

const InquiryAndGallary = () => {
  const { inquiryEntrySchema, InquiryEntryHome, alert, setAlert } =
    useBigBurger();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setValue,
  } = useForm({
    resolver: joiResolver(inquiryEntrySchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      contact_num: "",
      email_id: "",
    },
  });

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 10));
  };

  const onUserSubmit = useCallback(async () => {
    try {
      const res = await InquiryEntryHome({
        ...getValues(),
      });
      if (res == true) {
        setAlert(true);
        reset();
        dispatch(resetInquiryFormData());
        setValue("");
      }
    } catch (err) {
      console.log("errr", err);
    }
  }, [InquiryEntryHome]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert(false);
    }, 3000);

    // To clear or cancel a timer, you call the clearTimeout(); method,
    // passing in the timer object that you created into clearTimeout().

    return () => clearTimeout(timer);
  }, [alert]);

  const dispatch = useDispatch();
  const images = [
    { image: Item1 },
    { image: Item2 },
    { image: Item3 },
    { image: Item4 },
    { image: Item5 },
    { image: Item6 },
  ];
  return (
    <section className="flex flex-col w-full md:gap-10 pt-8">
      <div className="flex flex-col w-full relative justify-center items-center md:px-8 lg:px-20 xl:px-36 md:pb-8">
        <img
          src={FormElement1}
          alt="Element"
          className="absolute bottom-0 xl:-bottom-6 right-2 md:right-auto md:left-12 lg:left-24 xl:left-36 object-contain w-16 md:w-24 xl:w-32"
        />
        <img
          src={FormElement2}
          alt="Element"
          className="absolute -top-16 md:top-0 left-6 md:left-auto md:right-10 lg:right-32 xl:right-56  right: 5rem;  object-contain w-20 md:w-24 xl:w-32"
        />
        {/* <p className="bg-black text-white w-11/12 lg:w-3/5 py-4 px-6 rounded-t-3xl text-base 2xl:text-3xl text-center font-thin hidden md:flex">
          FOR FRANCHISE INQUIRY, CALL{" "}
          <span className="font-description-bold"> +91 99048 84433</span>
        </p>
        <p className="bg-black text-white w-11/12 py-2 px-8 rounded-t-3xl text-base text-center font-thin flex md:hidden flex-col">
          FOR FRANCHISE INQUIRY,
          <span>
            CALL <span className="font-description-bold"> +91 99048 84433</span>
          </span>
        </p> */}
        <form className="bg-yellow w-full md:rounded-3xl flex flex-col gap-4 md:gap-10 justify-center items-center px-8 md:px-20 py-6">
          <p className="font-title text-4xl lg:text-6xl">Let’s get talking</p>
          <div className="flex w-full gap-4 md:gap-10 flex-wrap flex-col lg:flex-row">
            <input
              type="text"
              placeholder="First Name"
              className="flex-1 py-2 md:py-4 px-4 md:px-6 rounded-xl border-black border-opacity-50 border-2 bg-transparent text-white placeholder-gray-50"
              {...register("first_name")}
              autoComplete="false"
            />
            <input
              type="text"
              placeholder="Last Name"
              className="flex-1 py-2 md:py-4 px-4 md:px-6 rounded-xl border-black border-opacity-50 border-2 bg-transparent text-white placeholder-gray-50"
              {...register("last_name")}
              autoComplete="false"
            />
          </div>
          <div className="flex w-full gap-4 md:gap-10 flex-col lg:flex-row">
            <input
              type="text"
              placeholder="Your E-mail ID"
              className="flex-1 py-2 md:py-4 px-4 md:px-6 rounded-xl border-black border-opacity-50 border-2 bg-transparent text-white placeholder-gray-50"
              {...register("email_id")}
              autoComplete="false"
            />
            <input
              type="text"
              placeholder="Mobile Number"
              className="flex-1 py-2 md:py-4 px-4 md:px-6 rounded-xl border-black border-opacity-50 border-2 bg-transparent text-white placeholder-gray-50"
              {...register("contact_num")}
              autoComplete="false"
              onInput={(e) => onlyNumbers(e)}
            />
          </div>
          <button
            className="px-20 py-2 text-yellow bg-black rounded-xl font-extrabold text-xl lg:text-3xl"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(onUserSubmit());
            }}
          >
            SUBMIT
          </button>
          {alert === true ? (
            <div className="fade_in" style={{ color: "white" }}>
              Thank You For Showing Interest!
            </div>
          ) : null}
        </form>
      </div>
      <GalleryCarousel images={images} />
    </section>
  );
};

export default InquiryAndGallary;
