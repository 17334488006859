import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useApi } from "../apiCall/useApi";
import { Urls } from "../../../utils/constant";
import Joi from "joi";
import {
  setInquiryList,
  setRestaurantProductList,
  setBranchMasterComboList,
  setBranchData
} from "../../reducers/bigBurger.reducer";

import { size } from "lodash";

export const inquiryEntrySchema = Joi.object({
  name : Joi.string().required(),
  email_id: Joi.string().required(),
  contact_num: Joi.string().required(),
});

export default function useBigBurger() {
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [branchId, setBranchId] = useState(null);
  const [menuId, setMenuId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);

  const InquiryEntryHome = useCallback(
    async ({ first_name, last_name, contact_num, email_id }) => {

      if (loading) return;
      setLoading(true);
      try {
      
        //showLoader();
        const { data } = await apiPost({
          url: Urls.inquiryAdd(),
          data: {
            name: first_name + " " + last_name,
            email_id: email_id,
            contact_num: contact_num,
          },
        });
        dispatch(setInquiryList(data.id));
       

        let id = data.id
        if (size(id) === 36) {
          return true
        }
        setLoading(false);
        // hideLoader();
      } catch (err) {
        // hideLoader();
        setLoading(false);
        console.log(err);
        return false;
      }
    },
    [loading]
  );

  const fetchRestaurantProductList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getRestaurantProduct(),
        data: {
          branch_id: branchId,
          page_size: 12,
          current_page: 1,
        },
      });
      dispatch(setRestaurantProductList(data));
    } catch (err) {
      console.log(err);
    }
  }, [branchId]);

  const fetchBranchMasterComboList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getBranchMasterCombo(),
        data: {},
      });
      dispatch(setBranchMasterComboList(data.data));
      // setting list to reducer
      if (size(branchId) <= 0) {
        setBranchId(size(data) > 0 ? data.data[0].id : "");
        if (size(data) > 0) {
          await fetchRestaurantProductList({
            branch_id: branchId,
            page_size: 12,
            current_page: 1,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [branchId]);

  const handleRestaurantCategoryNameChange = useCallback(
    async (e) => {
      setCategoryId(e.target.value);
    },
    [branchId]
  );

  const handleBranchMasterComboChange = useCallback(
    async (e) => {
      setBranchId(e.target.value);
      await fetchRestaurantProductList({ branch_id: e.target.value });
    },
    [fetchRestaurantProductList]
  );
  
  const InquiryEntryFranchise = useCallback(
    async ({ first_name, last_name, contact_num, email_id }) => {

      if (loading) return;
      setLoading(true);
      try {
        
        //showLoader();
        const { data } = await apiPost({
          url: Urls.inquiryAdd(),
          data: {
            name: first_name + " " + last_name,
            email_id: email_id,
            contact_num: contact_num,
          },
        });
        dispatch(setInquiryList(data.id));
       

        let id = data.id
        if (size(id) === 36) {
          return true
        }
        setLoading(false);
        // hideLoader();
      } catch (err) {
        // hideLoader();
        setLoading(false);
        console.log(err);
        return false;
      }
    },
    [loading]
  );

  const ContactEntry = useCallback(
    async ({ name, email_id,contact_num, message  }) => {

      if (loading) return;
      setLoading(true);
      try {
        //showLoader();
      
        
        const { data } = await apiPost({
          url: Urls.inquiryAdd(),
          data: {
            name: name,
            email_id: email_id,
            contact_num: contact_num,
            message: message,
          },
        });
        
        dispatch(setInquiryList(data.id));
        let id = data.id
        if (size(id) === 36) {          
          return true
        }
      } catch (err) {
        // hideLoader();
        setLoading(false);
        console.log(err);
        return false;
      }
    },
    [loading]
  );


  return {
    InquiryEntryHome,
    InquiryEntryFranchise,
    ContactEntry,
    inquiryEntrySchema,
    alert,
    setAlert,
    fetchRestaurantProductList,
    fetchBranchMasterComboList,
    handleRestaurantCategoryNameChange,
    handleBranchMasterComboChange,
    branchId,
  };
}
