import React, { useEffect } from "react";
import AboutBanner from "../aboutContainers/AboutBanner";
import VissionMission from "../aboutContainers/VissionMission";
import GalleryCarousel from "../components/GalleryCarousel";
import Item1 from "../assets/images/galler_1.png";
import Item2 from "../assets/images/galler_2.png";
import Item3 from "../assets/images/galler_3.png";
import Item4 from "../assets/images/galler_4.png";
import Item5 from "../assets/images/galler_5.png";
import Item6 from "../assets/images/galler_6.png";
import Inquiry from "../assets/images/about/about_page_inquiry_bg.png";
import AboutFounders from "../aboutContainers/AboutFounders";
import { useLocation } from "react-router-dom";

const About = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const images = [
    { image: Item1 },
    { image: Item2 },
    { image: Item3 },
    { image: Item4 },
    { image: Item5 },
    { image: Item6 },
  ];
  return (
    <>
      <AboutBanner />
      <VissionMission />
      <AboutFounders />
      {/* <span
        className={`relative py-8 xl:py-16 hidden lg:flex justify-center items-center w-full bg-no-repeat bg-center bg-contain z-10 -mb-8 bg-InquiryBG`}
      /> */}
      {/* <p className="flex flex-wrap justify-center items-center lg:hidden bg-black text-white font-description text-center rounded-t-3xl text-xl p-4 mx-4 md:mx-8">
        {`FOR FRANCHISE INQUIRY, 
CALL`}
        <span className="font-description-bold"> +91 99048 84433</span>
      </p> */}
      <GalleryCarousel images={images} />
    </>
  );
};

export default About;
