import React from "react";
import ReportCardBG from "../assets/images/franchise/report_card.png";
import ReportCardMobile from "../assets/images/franchise/ReportCardMobile.png";
import Outlets from "../assets/images/franchise/outlets.png";
import pinkSmile from "../assets/images/franchise/pinkSmile.png";
import guaranteed from "../assets/images/franchise/guaranteed.png";
import profits from "../assets/images/franchise/profits.png";
import Flag from "../assets/images/franchise/flag.png";
import OutletsMobile from "../assets/images/franchise/OutletsMobile.png";
import MarginMobile from "../assets/images/franchise/MarginMobile.png";
import ProfitableMobile from "../assets/images/franchise/ProfitableMobile.png";
import ROIMobile from "../assets/images/franchise/ROIMobile.png";
import CustomersMobile from "../assets/images/franchise/CustomersMobile.png";
import Border from "../assets/images/balckBorder.png";

const ReportCard = () => {
  return (
    <section className="relative flex mx-4 md:mx-8 lg:mx-20 xl:mx-36 -mb-12 lg:mb-0 lg:pb-16 z-10">
      <img
        src={ReportCardBG}
        alt="Report Card"
        className="h-96 md:h-auto w-full object-cover md:object-contain hidden lg:flex"
      />
      <img
        src={ReportCardMobile}
        alt="Report Card"
        className="object-contain w-full flex lg:hidden"
      />
      <img
        src={Flag}
        alt="Flag"
        className="absolute object-contain h-36 md:h-48 xl:h-60 -top-16 md:-top-20 lg:-top-28 xl:-top-32 right-4 lg:right-20"
      />
      <div className="absolute top-14 md:top-0 left-0 bottom-0 w-full flex justify-center items-center flex-col px-2 md:px-8">
        <h3 className="font-title text-black text-lg md:text-5xl xl:text-5xl 2xl:text-7xl text-center tracking-wider">
          Take a look at our Report Card yourself!
        </h3>
        <p className="text-white md:font-description-semibold text-center text-xs md:text-lg xl:text-lg 2xl:text-2xl pt-2 xl:pt-4 lg:pb-4 xl:pb-12">
          In the 4 months since the launch of the very first Big Burger Co.,
          outlet, we’ve moved ahead at a trailblazing speed.
        </p>
        <div className="flex lg:hidden w-1/2 py-2">
          <img src={Border} alt="Border" className="object-contain" />
        </div>
        <div className="hidden lg:flex gap-4 md:gap-8 xl:gap-12 pb-8 flex-wrap justify-center items-center">
          <div className="flex flex-col gap-0 md:gap-4">
            <img
              src={Outlets}
              alt="Outlets"
              className="h-10 md:h-12 xl:h-24 object-contain"
            />
            <span className="font-title text-black text-center text-md md:text-2xl xl:text-4xl 2xl:text-5xl">
              5 Outlets <br /> across India
            </span>
          </div>
          <div className="flex flex-col gap-0 md:gap-4">
            <img
              src={pinkSmile}
              alt="Outlets"
              className="h-10 md:h-12 xl:h-24 object-contain"
            />
            <span className="font-title text-black text-center text-md md:text-2xl xl:text-4xl 2xl:text-5xl">
              250k <br /> Customers
            </span>
          </div>
          <div className="flex flex-col gap-0 md:gap-4">
            <img
              src={guaranteed}
              alt="Outlets"
              className="h-10 md:h-12 xl:h-24 object-contain"
            />
            <span className="font-title text-black text-center text-md md:text-2xl xl:text-4xl 2xl:text-5xl">
              Guaranteed ROI <br /> in 15-20 Months
            </span>
          </div>
          <div className="flex flex-col gap-0 md:gap-4">
            <img
              src={profits}
              alt="Outlets"
              className="h-10 md:h-12 xl:h-24 object-contain"
            />
            <span className="font-title text-black text-center text-md md:text-2xl xl:text-4xl 2xl:text-5xl">
              100% Profitable <br /> Outlets
            </span>
          </div>
        </div>
        <div className="relative flex lg:hidden gap-2 md:gap-4 h-16 md:h-36 mb-10 md:pt-8">
          <img
            src={OutletsMobile}
            alt="Outlets"
            className="object-contain self-start h-12 md:h-28"
          />
          <img
            src={CustomersMobile}
            alt="Happy Customers"
            className="object-contain self-end h-12 md:h-28"
          />
          <img
            src={ROIMobile}
            alt="ROI"
            className="object-contain self-start h-12 md:h-28"
          />
          <img
            src={ProfitableMobile}
            alt="Profitable Outlets"
            className="object-contain self-end h-12 md:h-28"
          />
          <img
            src={MarginMobile}
            alt="High Profit"
            className="object-contain self-start h-12 md:h-28"
          />
        </div>
      </div>
    </section>
  );
};

export default ReportCard;
